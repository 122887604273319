type UserData = {
  name: string
  email: string
  jiraId?: string
  position: string
}

export const storeUserData = ({
  name,
  email,
  jiraId,
  position,
}: UserData): void => {
  localStorage.setItem('name', name)
  localStorage.setItem('email', email)
  localStorage.setItem('jiraId', jiraId)
  localStorage.setItem('position', position)
}

export const getUserData = (): UserData => {
  const name = localStorage.getItem('name')
  const email = localStorage.getItem('email')
  const jiraId = localStorage.getItem('jiraId')
  const position = localStorage.getItem('position')
  return { name, email, jiraId, position }
}
