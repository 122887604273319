/* eslint-disable */

import './sass/app.scss'
import { getAnalyticsData, storeAnalyticsData } from './src/utils/analyticsData'
import { getUserData } from './src/utils/userData'
require('prismjs/themes/prism-twilight.css')

// if (location.protocol !== 'https:') {
//   location.replace(`https:${location.href.substring(location.protocol.length)}`);
// }

export const onClientEntry = async () => {
  storeAnalyticsData()
  const fbData = getAnalyticsData()
  const userData = getUserData()
  if (process.env.GATSBY_APP_STAGE === 'production') {
    await fetch(process.env.GATSBY_APP_API_URL + '/trackEvent', {
      method: `POST`,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...userData,
        ...fbData,
        eventType: 'PageView',
        sourceUrl: window.location.href,
      }),
    })
  }
}

export const onInitialClientRender = () => {
  setTimeout(() => {
    // Google Tag Manager
    if (process.env.GATSBY_APP_STAGE === 'production') {
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', 'GTM-PTQSNCG')
    }

    // Google Analytics
    if (process.env.GATSBY_GOOGLE_ANALYTICS_ID) {
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s)
        j.async = true
        j.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`
        f.parentNode.insertBefore(j, f)

        w.dataLayer = w.dataLayer || []
        function gtag() {
          dataLayer.push(arguments)
        }
        gtag('js', new Date())
        gtag('config', process.env.GATSBY_GOOGLE_ANALYTICS_ID)
      })(window, document, 'script', 'dataLayer')
    }
  }, 1000)
}
