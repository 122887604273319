const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

type AnalyticsData = {
  fbc: string
  fbp: string
}

export const storeAnalyticsData = (): void => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const queryParams = Object.fromEntries(urlSearchParams.entries())
  if (queryParams.fbclid) {
    localStorage.setItem('fbclid', queryParams.fbclid)
  }
}

export const getAnalyticsData = (): AnalyticsData => {
  const fbp = getCookie('_fbp') || ''
  const fbc = getCookie('_fbc') || localStorage.getItem('fbclid') || ''
  return { fbc, fbp }
}
